/*jshint esversion: 6 */

// js for devilsbridgebarn.com
// uses blissfuljs

const devilsbridgebarnjsVersionMajorNumber = 0;
const devilsbridgebarnjsVersionMinorNumber = 1;
const lastRevision = 1;

var FOR_LOCAL_PEOPLE = (window.location.hostname.indexOf("local") !== -1);
if (FOR_LOCAL_PEOPLE) console.log(`FOR LOCAL PEOPLE: ${FOR_LOCAL_PEOPLE}`);
var CMS_ID = "devilsbridgebarn.com";

// console interception 1.1
// delayed posting in case of error cascade

window.slowPostman;
window.consoleHistory = [];
if (!FOR_LOCAL_PEOPLE) {
  (function () {
    var log = console.log;
    console.log = function () {
      window.consoleHistory.push({
        'time' : new Date(),
        'log' : Array.prototype.slice.call(arguments)
      });
      log.apply(this, Array.prototype.slice.call(arguments));
    };
  }());
  
  (function () {
    var warn = console.warn;
    console.warn = function () {
      window.consoleHistory.push({
        'time' : new Date(),
        'warning' : Array.prototype.slice.call(arguments)
      });
      warn.apply(this, Array.prototype.slice.call(arguments));
    };
  }());

  (function () {
    var error = console.error;
    console.error = function () {
      window.consoleHistory.push({
        'time': new Date(),
        'error' : Array.prototype.slice.call(arguments)
      });
      window.clearTimeout(window.slowPostman);
      window.slowPostman = window.setTimeout(function(){
            postError(consoleHistory);
            return;
        },5000);
      error.apply(this, Array.prototype.slice.call(arguments));
    };
  }());
}
function postError(log) {
  var xhr = new XMLHttpRequest();
  xhr.open('POST', 'https://www.bodja.com/snitch.php');
  xhr.onload = function() {
    window.consoleHistory = [{
      'time' : new Date(),
      'meta' : '*** CLIENT SESSION CONTINUATION ***'
    }];
    console.log(xhr);
  };
  xhr.send(JSON.stringify({
    'log' : log,
    'version' : bodjaCMSAppVersion,
    'cms_id' : (typeof CMS_ID !== 'undefined') ? CMS_ID : window.location.host
  }));
}

console.log("devilsbridgebarn version " + devilsbridgebarnjsVersionMajorNumber + "." + devilsbridgebarnjsVersionMinorNumber);
console.log("Latest revision: " + lastRevision);

const IMAGE_ROTATION_INTERVAL = 3000; // 3 secs

var noop = function(){return;};

// quick kill dupes function

var uniq_fast = function(a) {
    var seen = {};
    var out = [];
    var len = a.length;
    var j = 0;
    for(var i = 0; i < len; i++) {
         var item = a[i];
         if(seen[item] !== 1) {
               seen[item] = 1;
               out[j++] = item;
         }
    }
    return out;
};

var elDirectives = [];
var fireDirectives = function(){
  elDirectives.forEach(function(fn){
    fn.call();
  });
};

let readyAction = (evt) => {

  let generalContent;
  if (generalContent = $('.general-content')) {

    // markup paras

    $$('p',generalContent).forEach(function(para){

      // markup images

      let images = $$('img',para);
      if ((images.length == 1) && (para.innerText.length < 1)) {
        para.classList.add('sole-image');
      }
      else if (para.innerText.length < 1) {
        para.classList.add('multi-image');          
      }
      else {
        para.classList.add('para-image');         
        // images to top of para
        images.forEach(function(imgEl){
          imgEl.parentNode.prepend(imgEl);
        });
      }

      // markup sole links

      if ($$("a",para).length == para.childNodes.length) {
        let anchors = $$("a",para);
        anchors.forEach(function(anchor) {
          anchor.parentNode.classList.add("has-sole-link");
          if (anchor.innerText.length < 40) {
            anchor.classList.add("sole-link","btn","btn-success");
          }
        });
      }

    });

    // rotate multi-image divs

    let multiImageParas;
    if (multiImageParas = $$("p.multi-image")) {
      multiImageParas.forEach((para,i) => {
 
        // set last in set to active

        let active = $("img.active",para);
        if (!active) {
          $("img:last-of-type",para).classList.add("active");
          active = $("img.active",para);
          $$("img:not(.active)",para).forEach(el=>el.classList.add("inactive"));
        }

        // set rotator

        if (!Array.isArray(window.rotators)) window.rotators = [];
        window.rotators[i] = null;

        const rotator = () => {
          if (window.imageViewerOpen) return;
          active = $$("img.active",para);
          let activePreviousSibling = active.previousSibling;
          if (!(!!activePreviousSibling)) {
            let last = $("img:last-of-type",para);
            para.prepend(last);
            activePreviousSibling = active[0].previousSibling;
          }
          let nextSrc = (activePreviousSibling || {}).currentSrc || (activePreviousSibling || {}).src;
          let dummy = new Image();
          dummy.onload = (evt) => {
            window.requestAnimationFrame(()=>{
              active.forEach(el=> el.classList.add('inactive'));
              active.forEach(el => el.classList.remove("active"));
              activePreviousSibling.classList.remove('inactive');
              activePreviousSibling.classList.add('active');
            });
          };
          dummy.src = nextSrc || "";
        };

        // stagger intervals between rotators

        window.setTimeout(()=>{
          window.rotators[i] = window.setInterval(rotator,IMAGE_ROTATION_INTERVAL);          
        },i*(IMAGE_ROTATION_INTERVAL/2));

      });
    }

      // set image slideshow

      var openImageViewer = function(evt){

          $("body").classList.add("image-viewer-open");
          window.imageViewerOpen = true;

          var images = $$('img',generalContent);
          var viewer = $.create('div',{
              className : 'image-viewer-modal',
              contents : [{
                  tag : 'div',
                  className : 'image-viewer-actual',
                  contents : images.map(function(el){
                      var cloneImg = el.cloneNode();
                      cloneImg.classList.add('lozad');
                      cloneImg.dataset.loaded = 'false';
                      cloneImg._.unbind('click');
                      cloneImg._.style({
                          'flex-grow': cloneImg.naturalHeight / cloneImg.naturalWidth
                      });
                      return cloneImg;
                  })
              }],
              events : {
                  'click' : function(evt) {
                    evt.stopPropagation(); 
                    $("body").classList.remove("image-viewer-open");
                    window.imageViewerOpen = false;
                    this.remove();
                  }
              }
          });

          $('body').appendChild(viewer);

          // observer.observe();

      };

      $$('p.multi-image',generalContent).forEach(function(el){
          el.addEventListener('click',openImageViewer);
      });

  }

};

$.ready().then(readyAction);